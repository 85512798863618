/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PaypalSmartButtonStyleConfigType } from '../../type/Paypal.type';

import './PaypalSmartButtons.style';

export class PaypalSmartButtonsComponent extends PureComponent {
    static propTypes = {
        page: PropTypes.string.isRequired,
        handleOnClick: PropTypes.func.isRequired,
        handleOnError: PropTypes.func.isRequired,
        handleOnApprove: PropTypes.func.isRequired,
        handleOnCancel: PropTypes.func.isRequired,
        handleCreateOrder: PropTypes.func.isRequired,
        paypal: PropTypes.shape({
            Buttons: PropTypes.func
        }),
        style: PaypalSmartButtonStyleConfigType.isRequired
    };

    static defaultProps = {
        paypal: null
    };

    renderButtons() {
        const {
            style,
            handleOnClick,
            handleOnError,
            handleOnApprove,
            handleOnCancel,
            handleCreateOrder,
        } = this.props;

        return (
            <PayPalScriptProvider options={{ 
                "client-id": "AUZfbDQ_4m8ibp82qV9pi9wxGkGrdGILVYWbWaTWreW9mmTm6LjQorLZxpP7kjymXc7flRnepHBFSQWp",
                "currency": "CAD",
                "buyerCountry": "CA",
                "locale": "fr_CA",
                "intent": "authorize",
            }}>
                <PayPalButtons
                    fundingSource="paypal"
                    onClick={handleOnClick}
                    createOrder={handleCreateOrder}
                    onApprove={handleOnApprove}
                    onCancel={handleOnCancel}
                    onError={(err) => {
                        console.error('PayPal Buttons onError:', err);
                        handleOnError(err);
                    }}
                    style={style}
                />
            </PayPalScriptProvider>
        );
    }

    render() {
        const { page } = this.props;

        return (
            <div
                id={`${page}-buttons`}
                block="PaypalSmartButtons"
            >
                {this.renderButtons()}
            </div>
        );
    }
}

export default PaypalSmartButtonsComponent;
