/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import {isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import { fetchMutation } from 'Util/Request/Mutation';

import { PaypalExpressContext } from '../../context/PaypalExpress';
import PaypalExpressTokenMutation from '../../query/PaypalExpressToken.mutation';
import PaypalOnAuthorizationMutation from '../../query/PaypalOnAuthorization.mutation';
import PaypalOnCancelMutation from '../../query/PaypalOnCancel.mutation';
import { PaypalConfigsType } from '../../type/Paypal.type';
import PaypalSmartButtons from './PaypalSmartButtons.component';
import { PAYPAL_EXPRESS_PAYMENT_METHOD_CODE, PAYPAL_PLACEMENT_CHECKOUT_PAGE } from './PaypalSmartButtons.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/PaypalExpress/Component/PaypalSmartButtons/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer?.cartTotals?.id,
    paypalConfigs: state.ConfigReducer?.paypalConfigs
});

/** @namespace Scandiweb/PaypalExpress/Component/PaypalSmartButtons/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, getAuthorizationToken())
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        }
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/* @namespace Scandiweb/PaypalExpress/Component/PaypalSmartButtons/Container */
export class PaypalSmartButtonsContainer extends PureComponent {
    static propTypes = {
        cartId: PropTypes.string,
        page: PropTypes.string.isRequired,
        addToCart: PropTypes.func,
        hasError: PropTypes.func,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        paypalConfigs: PaypalConfigsType,
        setOrderButtonVisibility: PropTypes.func
    };

    static defaultProps = {
        cartId: '',
        addToCart: null,
        hasError: () => {},
        paypalConfigs: {},
        setOrderButtonVisibility: null

    };

    static contextType = PaypalExpressContext;

    containerFunctions = {
        handleOnClick: this.handleOnClick.bind(this),
        handleOnError: this.handleOnError.bind(this),
        handleOnApprove: this.handleOnApprove.bind(this),
        handleOnCancel: this.handleOnCancel.bind(this),
        handleCreateOrder: this.handleCreateOrder.bind(this)
    };

    componentDidMount() {
        const { setOrderButtonVisibility } = this.props;

        if (!setOrderButtonVisibility) {
            return;
        }

        setOrderButtonVisibility(false);
    }

    componentWillUnmount() {
        const { setOrderButtonVisibility } = this.props;

        if (!setOrderButtonVisibility) {
            return;
        }

        setOrderButtonVisibility(true);
    }

    async handleOnClick(data, actions) {
        const {
            cartId,
            addToCart,
            hasError,
            paypalConfigs: {
                isGuestCheckoutAllowed = false
            } = {}
        } = this.props;

        if (!cartId) {
            actions.reject();
            return false;
        }

        // vvv if customer isn't signed in and guest checkout is disabled
        if (!isSignedIn() && !isGuestCheckoutAllowed) {
            actions.reject();
            return false;
        }

        // vvv if we aren't using smart buttons from product page
        if (!addToCart) {
            actions.resolve();
            return true;
        }

        const isHasError = hasError();

        // vvv if product's configurable options weren't selected, prevent smart buttons from creating an order
        if (isHasError) {
            actions.reject();
            return false;
        }

        await addToCart();

        actions.resolve();
        return true;
    }

    async handleCreateOrder() {
        const { page, cartId } = this.props;
        const { setToken } = this.context;

        if (!cartId) {
            return null;
        }

        const params = {
            cart_id: cartId,
            code: PAYPAL_EXPRESS_PAYMENT_METHOD_CODE,
            urls: {
                cancel_url: 'paypal/express/review',
                return_url: 'paypal/express/review'
            },
            // vvv smart button on checkout page isn't considered as an express button
            express_button: page !== PAYPAL_PLACEMENT_CHECKOUT_PAGE
        };

        const { createPaypalExpressToken: { token } = {} } = await fetchMutation(
            PaypalExpressTokenMutation.getPaypalExpressToken(params)
        );

        setToken(token);
        // localStorage.setItem('token', token);
        return token;
    }

    async handleOnApprove(data) {
        const {
            cartId,
            showNotification
        } = this.props;
        const { orderID, payerID } = data;

        try {
            const {
                paypalOnAuthorization: {
                    redirectUrl: redirectPath
                } = {}
            } = await fetchMutation(
                PaypalOnAuthorizationMutation.getPaypalOnAuthorization(cartId, payerID, orderID)
            );

            window.location.replace(window.location.origin + redirectPath);
        } catch (error) {
            showNotification('error', __('Something went wrong with your request. Please try again later.'));
        }
    }

    async handleOnCancel() {
        const { showNotification } = this.props;

        const { paypalOnCancel: { message } = {} } = await fetchMutation(
            PaypalOnCancelMutation.onCancel()
        );

        showNotification('info', message);
    }

    handleOnError() {
        const { showNotification } = this.props;

        showNotification('error', __('Something went wrong with your request. Please try again later.'));
    }

    getSmartButtonStyleConfig() {
        const {
            page,
            paypalConfigs: {
                paypalSmartButtonsConfig
            } = {}
        } = this.props;

        if (!paypalSmartButtonsConfig) {
            return {};
        }

        return paypalSmartButtonsConfig[page];
    }

    containerProps() {
        const { page } = this.props;
        const { paypal } = this.context;

        return {
            page,
            paypal,
            style: this.getSmartButtonStyleConfig()
        };
    }

    render() {
        return (
            <PaypalSmartButtons
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalSmartButtonsContainer);
